import { API_URL } from './config';

export async function getUsersAll(
  authToken,
  page,
  pageSize,
  orderBy,
  orderByValue
) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/user/${page}/${pageSize}/${orderBy}/${orderByValue}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export async function getUser(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export async function getUserByEmail(authToken, email) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/user/email/${email}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const updateUser = async (authToken, id, data) => {
  const res = await fetch(`${API_URL}/user/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'Application/json'
    },
    body: JSON.stringify({ data })
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error during the add amount');
  }
  return await res.json();
};

export const deleteUser = async (authToken, id) => {
  const res = await fetch(`${API_URL}/user/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'Application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error during the delete account');
  }
  return await res;
};
