import React from 'react';
import { Button, Card } from 'react-bootstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import DebitListTableHeader from './DebitListTableHeader';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import NumberFormat from '../NumberFormat';
import CustomTableFooter from './CustomTableFooter';
import PropTypes from 'prop-types';
import moment from 'moment';
import { updateDebit } from 'admin-dashboard/api/debit';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { MdCancel, MdOutlineDownloadDone } from 'react-icons/md';
import { toast } from 'react-toastify';

const DebitListTable = ({
  data,
  name,
  page,
  setPage,
  getDebitTableDetails
}) => {
  const { authToken } = useAuth();

  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
      meta: {
        headerProps: {
          style: { minWidth: '8rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { id } = original;
        return <p className="fw-semibold text-900">{id}</p>;
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { amount } = original;
        return (
          <p className="fw-semibold">
            {amount && NumberFormat.currencyFormat(amount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'accountType',
      header: 'Account Type',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { accountType } = original;
        return <p className="fw-semibold">{accountType}</p>;
      }
    },
    {
      accessorKey: 'accountNumber',
      header: 'Account No',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { accountNumber } = original;
        return (
          <p className="fw-semibold">{accountNumber ? accountNumber : '-'}</p>
        );
      }
    },
    {
      accessorKey: 'accountIFSC',
      header: 'IFSC',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { accountIFSC } = original;
        return <p className="fw-semibold">{accountIFSC ? accountIFSC : '-'}</p>;
      }
    },
    {
      accessorKey: 'upiAddress',
      header: 'UPI Address',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { upiAddress } = original;
        return (
          <p className="fw-semibold text-900">
            {upiAddress ? upiAddress : '-'}
          </p>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: {
          style: { minWidth: '3rem' },
          className: 'text-center text-900'
        },
        cellProps: { className: 'fs-9' }
      },
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <SubtleBadge
            pill
            bg={
              status === 'processing'
                ? 'primary'
                : status === 'success'
                ? 'success'
                : 'danger'
            }
            className="d-block"
          >
            {status}
          </SubtleBadge>
        );
      }
    },
    {
      accessorKey: 'wallet',
      header: 'Wallet',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { User } = original;
        return (
          <p className="fw-semibold text-900">
            {NumberFormat.currencyFormat(User?.wallet)}
          </p>
        );
      }
    },
    {
      accessorKey: 'user',
      header: 'User',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { User } = original;
        return (
          <Link to={paths.userDetails(User?.id)}>
            <p className="fw-semibold text-900">{User?.id}</p>
            <p className="fw-semibold text-900">{User?.email}</p>{' '}
          </Link>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: 'Action',
      meta: {
        headerProps: {
          className: 'text-900'
        }
      },
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { User, status } = original;
        return (
          <>
            {status === 'processing' ? (
              <div>
                <Button
                  onClick={async () => {
                    let data = {
                      status: 'success',
                      successDate: moment(new Date()).format(
                        'DD-MM-YYYY hh:mm a'
                      )
                    };
                    await updateDebit(authToken, User?.id, data).then(r =>
                      toast.success(r?.status, {
                        theme: 'colored'
                      })
                    );
                    getDebitTableDetails(1, 10);
                  }}
                  variant="success"
                >
                  <MdOutlineDownloadDone />
                </Button>
                <Button
                  onClick={async () => {
                    let data = {
                      status: 'cancel',
                      successDate: moment(new Date()).format(
                        'DD-MM-YYYY hh:mm a'
                      )
                    };
                    await updateDebit(authToken, User?.id, data).then(r =>
                      toast.error(r?.status, {
                        theme: 'colored'
                      })
                    );
                    getDebitTableDetails(1, 10);
                  }}
                  variant="secondary"
                  className="mx-2"
                >
                  <MdCancel />
                </Button>
              </div>
            ) : (
              <Button onClick={() => {}} variant="secondary">
                Processed
              </Button>
            )}
          </>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: data?.data,
    columns,
    sortable: true,
    perPage: 10
  });

  return (
    <AdvanceTableProvider {...table}>
      <Card>
        <Card.Header>
          <DebitListTableHeader name={name} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              size: 'sm',
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <CustomTableFooter
            page={page}
            setPage={setPage}
            totalPages={data?.totalPages}
            totalCount={data?.totalCount}
            nextPage={data?.nextPage}
            previousPage={data?.previousPage}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableProvider>
  );
};

DebitListTable.propTypes = {
  data: PropTypes.any,
  name: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  getDebitTableDetails: PropTypes.any
};

export default DebitListTable;
