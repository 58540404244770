import React, { useState, useEffect } from 'react';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { getUPI, updateUPI } from 'admin-dashboard/api/upi';
import { Button, Card, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';

function ProfileScreen() {
  const { authToken } = useAuth();
  const [upi, setUPI] = useState('');
  const [dbupi, setDBUPI] = useState('');

  const getUPIS = () => {
    getUPI(authToken)
      .then(res => {
        setDBUPI(res[0]);
        toast.success(res[0]?.upi === null ? 'RazorPay' : res[0]?.upi, {
          theme: 'colored'
        });
        setUPI('');
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getUPIS();
  }, []);

  return (
    <Card className="mb-3 p-2">
      <div className="mb-3 mx-2">
        <h5 className="fs-8">
          Payment Type = {dbupi?.upi === null ? 'RazorPay' : 'UPI'}
        </h5>
        <h5 className="fs-9 text-primary mt-1">
          {dbupi?.upi !== null && <p>Payment Type = {dbupi?.upi}</p>}
        </h5>

        <Flex alignItems={'center'} alignContent={'center'}>
          {dbupi?.upi === null && (
            <div>
              <Form.Control
                type="text"
                value={upi}
                name="UPI"
                placeholder="Enter UPI Id"
                onChange={e => setUPI(e.target.value)}
              />
            </div>
          )}
          <div className="mx-2">
            {dbupi?.upi === null ? (
              <Button
                onClick={async () => {
                  await updateUPI(authToken, dbupi?.id, { upi: upi });
                  getUPIS();
                }}
                variant="primary"
              >
                Change UPI
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  await updateUPI(authToken, dbupi?.id, { upi: null });
                  getUPIS();
                }}
                variant="primary"
              >
                Change RazorPay
              </Button>
            )}
          </div>
        </Flex>
      </div>
    </Card>
  );
}

export default ProfileScreen;
