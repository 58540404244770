import { API_URL } from './config';

export async function getAdminDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/admin/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}
