import React, { useState } from 'react';
import CandleChart from 'admin-dashboard/components/candle-chart/CandleChart';
import StatisticsCards from 'admin-dashboard/components/stats-cards/StatisticsCards';
import { Card, Col, Row } from 'react-bootstrap';
import BettingListTable from 'admin-dashboard/components/betting-table-color/BettingListTable';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { useEffect } from 'react';
import {
  deleteFiveMinutesBettingColorTable,
  getFiveMinutesBettingColorTable
} from 'admin-dashboard/api/betting';
import ProfitChart from 'admin-dashboard/components/ProfitChart';
import { Button, Form } from 'react-bootstrap';

function ColorFiveMinutes() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(1);
  const { authToken } = useAuth();

  const getBettingTableDetails = (pageNo, pageSize) => {
    getFiveMinutesBettingColorTable(authToken, pageNo, pageSize)
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getBettingTableDetails(1, 10);
  }, []);

  useEffect(() => {
    getBettingTableDetails(page, 10);
  }, [page]);

  const [formData, setFormData] = useState({
    count: ''
  });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const deleteTable = async count => {
    setDeleteLoading(true);
    await deleteFiveMinutesBettingColorTable(authToken, count)
      .then(() => {
        getBettingTableDetails(1, 10);
        setDeleteLoading(false);
        setFormData({
          count: ''
        });
      })
      .catch(e => console.log(e));
  };

  const handleSubmit = e => {
    e.preventDefault();
    deleteTable(formData.count);
  };

  return (
    <>
      {loading ? (
        <Card>
          <p className="p-3">Loading...</p>
        </Card>
      ) : (
        <Col xxl={9}>
          <Form onSubmit={handleSubmit} className="mb-3">
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Control
                  placeholder={'Count'}
                  value={formData.count}
                  name="count"
                  onChange={handleFieldChange}
                  type="number"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Button
                  type="submit"
                  color="primary"
                  className="w-100"
                  disabled={!formData.count && deleteLoading}
                >
                  {deleteLoading ? 'Loading...' : 'Delete'}
                </Button>
              </Form.Group>
            </Row>
          </Form>
          <StatisticsCards data={data?.statsData} />
          <Row className="g-3">
            <Col md={6}>
              <CandleChart data={data?.candleChartData} />
            </Col>
            <Col md={6}>
              <ProfitChart data={data?.profitChartData} />
            </Col>
            <Col xs={12}>
              <BettingListTable
                data={data}
                name={'One Minute'}
                page={page}
                setPage={setPage}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

export default ColorFiveMinutes;
