import { API_URL } from './config';

export const signup = async ({ email, username }) => {
  // console.log(JSON.stringify({ email, name, username }));
  const res = await fetch(`${API_URL}/super/signup`, {
    method: 'POST',
    headers: {
      'Content-type': 'Application/json'
    },
    body: JSON.stringify({
      email,
      username
    })
  });
  if (res.status === 400) {
    throw new Error('Email should be unique');
  } else {
    return res.json();
  }
};

export const login = async ({ email }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/super/login`, {
    method: 'POST',
    headers: {
      'Content-type': 'Application/json'
    },
    body: JSON.stringify({ email })
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error('user is not found');
  } else if (res.status === 402) {
    throw new Error('Invalid email or password');
  } else if (res.status !== 200) {
    throw new Error('Error during the login process');
  } else {
    return res.json();
  }
};

export const authenticate = async ({ email, emailToken }) => {
  const res = await fetch(`${API_URL}/super/authenticate`, {
    method: 'POST',
    headers: {
      'Content-type': 'Application/json'
    },
    body: JSON.stringify({ email, emailToken })
  });
  if (res.status !== 200) {
    throw new Error('Error during the login process');
  }
  return res.json();
};
