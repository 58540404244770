import React from 'react';
// import { Link } from 'react-router-dom';
import AuthCardLayout from './AuthCardLayout';
// import paths from 'routes/paths';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        ''
        // <p className="text-white">
        //   Don't have an account?
        //   <br />
        //   <Link
        //     className="text-white text-decoration-underline"
        //     to={paths.cardRegister}
        //   >
        //     Get started!
        //   </Link>
        // </p>
      }
    >
      <h3>Admin Login</h3>
      <LoginForm hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
