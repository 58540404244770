import React from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { rgbaColor } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (getThemeColor, dateList, valueList) => ({
  color: getThemeColor('gray-100'),
  visualMap: {
    show: false,
    type: 'continuous',
    dimension: 0,
    min: 0,
    max: dateList.length - 1,
    color: [getThemeColor('danger'), getThemeColor('success')]
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: params => {
      const { name, value } = params[0];
      return `${name} : ${value}`;
    }
  },
  xAxis: {
    type: 'category',
    data: dateList,
    axisLabel: {
      formatter: value => value,
      color: getThemeColor('gray-500'),
      margin: 15
    },
    boundaryGap: false,
    axisTick: { show: false },
    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'solid'
      }
    },
    axisPointer: {
      lineStyle: {
        color: getThemeColor('gray-300')
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getThemeColor('gray-500'),
      margin: 15
    },
    boundaryGap: false,
    axisTick: { show: false },
    axisLine: { show: false },
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getThemeColor('gray-200'),
        type: 'dashed'
      }
    }
  },
  grid: { right: '3%', left: '8%', bottom: '10%', top: '5%' },
  series: {
    name: 'Total',
    type: 'line',
    showSymbol: false,
    symbolSize: 10,
    symbol: 'circle',
    data: valueList,
    smooth: false,
    itemStyle: {
      color: getThemeColor('white'),
      borderWidth: 2
    },
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: rgbaColor(getThemeColor('success'), 0.2)
          },
          {
            offset: 1,
            color: rgbaColor(getThemeColor('success'), 0)
          }
        ]
      }
    }
  }
});

const ProfitChart = ({ data }) => {
  const { getThemeColor } = useAppContext();

  const dateList = data.map(function (item) {
    return item[0];
  });

  const valueList = data.map(function (item) {
    return item[1];
  });

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Company Profit Form Each ID"
        titleTag="h6"
        className="pb-0"
      />
      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(getThemeColor, dateList, valueList)}
          style={{ height: '18.4375rem' }}
        />
      </Card.Body>
    </Card>
  );
};

ProfitChart.propTypes = {
  data: PropTypes.any.isRequired
};

export default ProfitChart;
