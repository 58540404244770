import React, { useEffect, useState } from 'react';
import UserTable from './user-tabel/UserTable';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { deleteUser, getUsersAll } from 'admin-dashboard/api/user';
import { Card, Col, Row } from 'react-bootstrap';
import { getTimer, getTimerFive, getTimerOne } from 'admin-dashboard/api/timer';
import moment from 'moment';
import Timer from './Timer';

function Default() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(1);
  // const [orderBy, setOrderBy] = useState('id');
  // const [orderByValue, setOrderByValue] = useState('desc');

  const orderBy = 'id';
  const orderByValue = 'desc';

  const { authToken } = useAuth();
  const [serverTimer, setServerTimer] = useState(null);
  const [serverTimerOne, setServerTimerOne] = useState(null);
  const [serverTimerFive, setServerTimerFive] = useState(null);
  const [serverLoading, setServerLoading] = useState(null);

  const getUsersTableDetails = (pageNo, pageSize, orderBy, orderByValue) => {
    setLoading(true);
    getUsersAll(authToken, pageNo, pageSize, orderBy, orderByValue)
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  const getTimerDetail = () => {
    getTimer(authToken)
      .then(res => {
        setServerTimer(res.timer);
        setServerLoading(null);
      })
      .catch(e => console.log(e));
  };

  const getTimerDetailOne = () => {
    getTimerOne(authToken)
      .then(res => {
        setServerTimerOne(res.timer);
      })
      .catch(e => console.log(e));
  };

  const getTimerDetailFive = () => {
    getTimerFive(authToken)
      .then(res => {
        setServerTimerFive(res.timer);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getUsersTableDetails(1, 10, orderBy, orderByValue);
    getTimerDetail();
    getTimerDetailOne();
    getTimerDetailFive();
  }, []);

  useEffect(() => {
    if (serverLoading) {
      getUsersTableDetails(1, 10, orderBy, orderByValue);
      getTimerDetail();
      getTimerDetailOne();
      getTimerDetailFive();
    }
  }, [serverLoading]);

  useEffect(() => {
    getUsersTableDetails(1, 10, orderBy, orderByValue);
  }, [page]);

  const deleteUserAccount = async (authToken, id) => {
    await deleteUser(authToken, id);
    getUsersTableDetails(1, 10, orderBy, orderByValue);
  };

  const timerOne = moment.unix(serverTimerOne);
  const timerThree = moment.unix(serverTimer);
  const timerFive = moment.unix(serverTimerFive);
  const [remainingTimeOne, setRemainingTimeOne] = useState(null);
  const [remainingTimeThree, setRemainingTimeThree] = useState(null);
  const [remainingTimeFive, setRemainingTimeFive] = useState(null);

  //One Minute
  useEffect(() => {
    if (timerOne) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const timeDifference = timerOne.diff(currentTime);

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTimeOne(moment.duration(0));

          // Check if the timer has already reached zero
          if (!serverLoading) {
            setServerLoading(true);
            // console.log("Timer reached zero");
          }
        } else {
          setRemainingTimeOne(moment.duration(timeDifference));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timerOne, serverLoading]);

  //Three Minute
  useEffect(() => {
    if (timerThree) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const timeDifference = timerThree.diff(currentTime);

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTimeThree(moment.duration(0));
          if (!serverLoading) {
            setServerLoading(true);
            // console.log("Timer reached zero");
          }
        } else {
          setRemainingTimeThree(moment.duration(timeDifference));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timerOne, serverLoading]);

  //Five Minute
  useEffect(() => {
    if (timerFive) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const timeDifference = timerFive.diff(currentTime);

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTimeFive(moment.duration(0));
          if (!serverLoading) {
            setServerLoading(true);
            // console.log("Timer reached zero");
          }
        } else {
          setRemainingTimeFive(moment.duration(timeDifference));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timerOne, serverLoading]);

  return (
    <>
      {loading ? (
        <Card>
          <p className="p-3 pt-4">Loading...</p>
        </Card>
      ) : (
        <>
          {/* {remainingTime === null ? (
            <div className="text-black">Loading...</div>
          ) : (
            <div className="flex flex-col items-end justify-center">
              <p className="font-medium text-sm text-black">Count Down</p>
              <div className="text-xl flex space-x-1 text-black">
                <span
                  className={`text-black text-center font-bold
                  rounded`}
                >
                  {digit1}
                </span>
                <span
                  className={`text-black text-center font-bold
                  rounded`}
                >
                  {digit2}
                </span>
                <span
                  className={`text-black text-center font-bold
                  rounded`}
                >
                  :
                </span>
                <span
                  className={`text-black text-center font-bold
                  rounded`}
                >
                  {digit3}
                </span>
                <span
                  className={`text-black text-center font-bold
                  rounded`}
                >
                  {digit4}
                </span>
              </div>
            </div>
          )} */}
          <Card className="my-3 p-2">
            <Row>
              <Col>
                <Timer remainingTime={remainingTimeOne} label={'Counter One'} />
              </Col>
              <Col>
                <Timer
                  remainingTime={remainingTimeThree}
                  label={'Counter Three'}
                />
              </Col>
              <Col>
                <Timer
                  remainingTime={remainingTimeFive}
                  label={'Counter Five'}
                />
              </Col>
            </Row>
          </Card>
          <UserTable
            data={data}
            page={page}
            setPage={setPage}
            deleteUserAccount={deleteUserAccount}
            getUsersTableDetails={() =>
              getUsersTableDetails(1, 10, orderBy, orderByValue)
            }
          />
        </>
      )}
    </>
  );
}

export default Default;
