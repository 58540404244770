import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import StatisticsCard from './StatisticsCard';

const StatisticsCards = ({ data }) => {
  return (
    <Row className="g-3 mb-3">
      {data?.map((stat, index) => (
        <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
          <StatisticsCard
            stat={stat}
            style={{ minWidth: '12rem' }}
            index={index}
          />
        </Col>
      ))}
    </Row>
  );
};

StatisticsCards.propTypes = {
  data: PropTypes.any
};

export default StatisticsCards;
