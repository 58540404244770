import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50
      }}
    >
      <Spinner animation="grow" variant="primary" />
    </div>
  );
};

export default Loading;
