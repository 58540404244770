import { API_URL } from './config';

export async function getUPI(authToken) {
  // if (!authToken) {
  //   return;
  // }

  const res = await fetch(`${API_URL}/upi/all-upi`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export async function updateUPI(authToken, id, data) {
  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/upi/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'Application/json'
    },
    body: JSON.stringify(data)
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}
