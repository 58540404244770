import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { useEffect } from 'react';
import { getCreditTable } from 'admin-dashboard/api/credit';
import CreditListTable from 'admin-dashboard/components/credit-table/CreditListTable';

function CreditTableView() {
  const { authToken } = useAuth();
  const [creditTable, setCreditTable] = useState(undefined);
  const [pageC, setPageC] = useState(1);
  const [refreshC, setRefreshC] = useState(true);

  const getCreditTableDetails = (pageNo, pageSize) => {
    getCreditTable(authToken, pageNo, pageSize)
      .then(res => {
        setCreditTable(res);
        setRefreshC(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getCreditTableDetails(1, 10);
  }, []);

  useEffect(() => {
    if (refreshC) {
      getCreditTableDetails(pageC, 10);
    }
  }, [refreshC, pageC]);

  return (
    <>
      {refreshC ? (
        <Card>
          <p className="p-3">Loading...</p>
        </Card>
      ) : (
        <Col xxl={9}>
          <Row className="g-3">
            <Col xs={12}>
              <CreditListTable
                data={creditTable}
                name={' '}
                page={pageC}
                setPage={setPageC}
                getCreditTableDetails={getCreditTableDetails}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

export default CreditTableView;
