import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import UserTableHeader from './UserTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Flex from 'components/common/Flex';
import CustomTableFooter from './CustomTableFooter';
import SubtleBadge from 'components/common/SubtleBadge';
import IconItem from 'components/common/icon/IconItem';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import NumberFormat from 'admin-dashboard/components/NumberFormat';
import { BsPencilFill } from 'react-icons/bs';
import { MdCancel, MdOutlineDone } from 'react-icons/md';
import { updateUser } from 'admin-dashboard/api/user';
import { useAuth } from 'admin-dashboard/context/AuthContext';

const UserTable = ({
  data,
  page,
  setPage,
  deleteUserAccount,
  getUsersTableDetails
}) => {
  const [walletAmount, setWalletAmount] = useState(0);
  const [isEditId, setIsEditId] = useState(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const { authToken } = useAuth();

  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'fs-9 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <Link to={paths.userDetails(id)}>
            <p className={`text-900`}>{id}</p>
          </Link>
        );
      }
    },
    {
      accessorKey: 'email',
      header: 'Email/Password',
      meta: {
        headerProps: {
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { email, password, emailVerified, number } = original;
        return (
          <div>
            <a href={'mailto:' + email} to={email} className="fw-semibold">
              {email}
            </a>
            <p className={`${emailVerified ? 'text-success' : 'text-danger'}`}>
              +91{number} / {password}
            </p>
          </div>
        );
      }
    },
    {
      accessorKey: 'userBettingItemTable',
      header: 'BettingItems',
      meta: {
        headerProps: {
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const {
          userBettingItemTable,
          userBettingItemColorTable,
          userBettingItemOneMinuteTable,
          userBettingItemColorOneMinuteTable,
          userBettingItemFiveMinutesTable,
          userBettingItemColorFiveMinutesTable
        } = original;
        return (
          <>
            <Flex>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemOneMinuteTable?.length}
              </p>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemColorOneMinuteTable?.length}
              </p>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemTable?.length}
              </p>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemColorTable?.length}
              </p>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemFiveMinutesTable?.length}
              </p>
              <p
                className={`text-900 mx-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => console.log('first')}
              >
                {userBettingItemColorFiveMinutesTable?.length}
              </p>
            </Flex>
          </>
        );
      }
    },
    {
      accessorKey: 'wallet',
      header: 'Wallet',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: isEditId ? '' : 'fs-9 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { id, wallet, totalDebit, totalCredit, totalWinning } = original;
        return (
          <>
            {isEdit && isEditId === id ? (
              <input
                required
                style={{
                  border: '1px solid #ccc',
                  padding: '5px',
                  outline: '1px solid #ccc',
                  borderRadius: '5px'
                }}
                type="text"
                name="wallet"
                placeholder={wallet}
                value={walletAmount}
                onChange={event => setWalletAmount(event.target.value)}
              />
            ) : (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsEdit(true);
                  setIsEditId(id);
                }}
              >
                <SubtleBadge bg={'success'} className="me-2">
                  {NumberFormat.currencyFormat(wallet)}
                  <BsPencilFill
                    size={15}
                    color={'green'}
                    style={{ marginLeft: 5 }}
                  />
                </SubtleBadge>
              </div>
            )}
            {isEdit && isEditId === id && (
              <div className="flex justify-between mt-2">
                <MdOutlineDone
                  className="mx-1"
                  size={30}
                  color={'green'}
                  onClick={async () => {
                    let data = {
                      wallet: parseInt(walletAmount),
                      totalDebit: totalDebit,
                      totalCredit: totalCredit,
                      totalWinning: totalWinning
                    };
                    try {
                      await updateUser(authToken, id, data);

                      setIsEdit(false);
                      setIsEditId(undefined);
                      setWalletAmount(0);
                      getUsersTableDetails();
                    } catch (error) {
                      setIsEdit(false);
                      setIsEditId(undefined);
                      setWalletAmount(0);
                      getUsersTableDetails();
                    }
                  }}
                />
                <MdCancel
                  className="mx-1"
                  size={30}
                  color={'red'}
                  onClick={() => {
                    setIsEdit(false);
                    setIsEditId(undefined);
                  }}
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      accessorKey: 'bonus',
      header: 'Bonus',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'fs-9 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { bonus } = original;
        return (
          <SubtleBadge bg={'warning'} className="me-2">
            {NumberFormat.currencyFormat(bonus)}
          </SubtleBadge>
        );
      }
    },
    {
      accessorKey: 'totalDebit',
      header: 'Debit (Credit)',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'fs-9 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { totalDebit, totalCredit } = original;
        return (
          <Flex>
            <p className={`text-danger`}>
              {NumberFormat.currencyFormat(totalDebit)}
            </p>
            <p className={`text-success mx-1`}>
              ({NumberFormat.currencyFormat(totalCredit)})
            </p>
          </Flex>
        );
      }
    },
    {
      accessorKey: 'totalWinning',
      header: 'Total Winning',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'fs-9 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { totalWinning } = original;
        return (
          <Flex>
            <p className={`text-success`}>
              {NumberFormat.currencyFormat(totalWinning)}
            </p>
          </Flex>
        );
      }
    },
    {
      accessorKey: 'action',
      header: 'Action',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'fs-9 pe-4'
        }
      },
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { id, email, emailVerified } = original;
        return (
          <div>
            <div className="hover-actions bg-100">
              <IconItem
                tag="button"
                icon="trash"
                size="sm"
                className="btn rounded-3 me-2 fs-11 text-danger"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  confirm('Are you sure!')
                    ? deleteUserAccount(id)
                    : console.log('');
                }}
              />
              {!emailVerified && (
                <IconItem
                  tag="button"
                  icon="envelope"
                  size="sm"
                  className="btn rounded-3 me-2 fs-11 text-primary"
                  onClick={() => {
                    const subject = 'User Conformation Request';
                    const body = `We are not received conformation email form your side. Send conformation email otherwise you will not received bonus amount!

You can reply with this email to confirm your account and get your bounces amount.

Thankyou,
DarkDice`;
                    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
                      subject
                    )}&body=${encodeURIComponent(body)}`;
                    window.location = mailtoLink;
                  }}
                />
              )}
            </div>
            <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" />
          </div>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    columns,
    data: data?.data,
    selection: false,
    sortable: true,
    pagination: true,
    perPage: 6,
    selectionColumnWidth: 52
  });

  return (
    <AdvanceTableProvider {...table}>
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <UserTableHeader count={data?.totalCount} />
        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="bg-body-tertiary align-middle"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              size: 'sm',
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <CustomTableFooter
            page={page}
            setPage={setPage}
            totalPages={data?.totalPages}
            totalCount={data?.totalCount}
            nextPage={data?.nextPage}
            previousPage={data?.previousPage}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableProvider>
  );
};

UserTable.propTypes = {
  data: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  deleteUserAccount: PropTypes.any,
  getUsersTableDetails: PropTypes.any
};

export default UserTable;
