// import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import paths from 'routes/paths';
import { authenticate, login } from 'admin-dashboard/api/auth';
import { useAuth } from 'admin-dashboard/context/AuthContext';

const LoginForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    // password: '',
    remember: false
  });
  const [loading, setLoading] = useState(false);

  const { updateAuthToken } = useAuth();

  const signIn = async email => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await login({ email: email })
        .then(async resp => {
          // console.log(resp);
          await authenticate({
            email: email,
            emailToken: resp?.createdToken?.emailToken
          })
            .then(async res => {
              if (res) {
                // console.log(res);
                await updateAuthToken(res?.authToken, res?.id?.toString());
                toast.success(`Welcome back ${formData.email}`, {
                  theme: 'colored'
                });
                setLoading(false);
              }
            })
            .catch(e => {
              toast.error(e?.message, {
                theme: 'colored'
              });
              setLoading(false);
            });
        })
        .catch(e => {
          toast.error(e?.message, {
            theme: 'colored'
          });
          setLoading(false);
        });
    } catch (error) {
      toast.error(error?.message, {
        theme: 'colored'
      });
      setLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    signIn(formData.email, formData.password);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      {/* <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group> */}

      {/* <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs-10 mb-0" to={forgotPasswordPaths[layout]}>
            Forgot Password?
          </Link>
        </Col>
      </Row> */}

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={(!formData.email || !formData.password) && loading}
        >
          {loading ? 'Loading...' : 'Log in'}
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
