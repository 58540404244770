/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export const CustomTableFooter = ({
  page,
  setPage,
  totalPages,
  totalCount,
  nextPage,
  previousPage,
  className
  // rowsPerPageOptions = [5, 10, 15]
}) => {
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}
    >
      <Flex alignItems="center" className="fs-10">
        <p className="mb-0">
          <span className="d-none d-sm-inline-block me-2">
            {page} of {totalPages} / ({totalCount})
          </span>
        </p>

        {/* <p className="mb-0 mx-2">Rows per page:</p>
        <Form.Select
          size="sm"
          className="w-auto"
          onChange={e => setPageSize(Number(e.target.value))}
          defaultValue={pageSize}
        >
          {rowsPerPageOptions.map(value => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </Form.Select> */}
      </Flex>

      <Flex>
        <Button
          size="sm"
          variant={previousPage !== null ? 'primary' : 'tertiary'}
          onClick={() => (previousPage === null ? null : setPage(previousPage))}
          className={classNames({
            disabled: previousPage === null
          })}
        >
          Previous
        </Button>
        <Button
          size="sm"
          variant={nextPage !== null ? 'primary' : 'tertiary'}
          className={classNames('px-4 ms-2', {
            disabled: nextPage === null
          })}
          onClick={() => (nextPage === null ? null : setPage(nextPage))}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default CustomTableFooter;
