import React from 'react';
import { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState('');
  const [userId, setUserId] = useState('1');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const storedToken = localStorage.getItem('authToken');
      const storedUserId = localStorage.getItem('userId');

      if (storedToken) {
        setAuthToken(storedToken);
      }
      if (storedUserId) {
        setUserId(storedUserId);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const updateAuthToken = async (newToken, userId) => {
    localStorage.setItem('authToken', newToken);
    localStorage.setItem('userId', userId);
    setAuthToken(newToken);
    setUserId(userId);
  };

  const removeAuthToken = async () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    setAuthToken(null);
    setUserId(null);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        authToken,
        userId,
        updateAuthToken,
        removeAuthToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuth = () => useContext(AuthContext);
