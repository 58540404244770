import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProfileBanner from './ProfileBanner';
import PropTypes from 'prop-types';
import NumberFormat from 'admin-dashboard/components/NumberFormat';

const Banner = ({ data }) => {
  const [rightSidedItems] = useState([
    {
      title: 'Wallet',
      value: data?.wallet
    },
    {
      title: 'Bonus',
      value: data?.bonus
    },
    {
      title: 'TotalWinning',
      value: data?.totalWinning
    },
    {
      title: 'TotalCredit',
      value: data?.totalCredit
    },
    {
      title: 'TotalDebit',
      value: data?.totalDebit
    }
  ]);

  return (
    <ProfileBanner>
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {data?.username} {data?.emailVerified && <VerifiedBadge />}
            </h4>
            <h5 className="fs-9 fw-normal">{data?.email}</h5>
            <p className="text-500">{data?.number}</p>
            <Button
              variant="falcon-primary"
              size="sm"
              className="px-3"
              onClick={() => {
                const subject = 'User Conformation Request';
                const body = `We are not received conformation email form your side. Send conformation email otherwise you will not received bonus amount!

You can reply with this email to confirm your account and get your bounces amount.

Thankyou,
DarkDice`;
                const mailtoLink = `mailto:${
                  data?.email
                }?subject=${encodeURIComponent(
                  subject
                )}&body=${encodeURIComponent(body)}`;
                window.location = mailtoLink;
              }}
            >
              Email Alert
            </Button>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {rightSidedItems.map(item => (
              <Link to="#!" key={item.title} className="link-900">
                <Flex alignItems="center" className="mb-2">
                  <div className="flex-1">
                    <h6 className="mb-0">{item.title}</h6>
                  </div>
                  <div className="flex-1">
                    <h6 className="mb-0">
                      {NumberFormat.currencyFormat(item.value)}
                    </h6>
                  </div>
                </Flex>
              </Link>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  data: PropTypes.any
};

export default Banner;
