import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import { useEffect } from 'react';
import { getDebitTable } from 'admin-dashboard/api/debit';
import DebitListTable from 'admin-dashboard/components/debit-table/DebitListTable';

function DebitTableView() {
  const { authToken } = useAuth();
  const [debitTable, setDebitTable] = useState(undefined);
  const [pageD, setPageD] = useState(1);
  const [refreshD, setRefreshD] = useState(true);

  const getDebitTableDetails = (pageNo, pageSize) => {
    getDebitTable(authToken, pageNo, pageSize)
      .then(res => {
        setDebitTable(res);
        setRefreshD(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getDebitTableDetails(1, 10);
  }, []);

  useEffect(() => {
    if (refreshD) {
      getDebitTableDetails(pageD, 10);
    }
  }, [refreshD, pageD]);

  return (
    <>
      {refreshD ? (
        <Card>
          <p className="p-3">Loading...</p>
        </Card>
      ) : (
        <Col xxl={9}>
          <Row className="g-3">
            <Col xs={12}>
              <DebitListTable
                data={debitTable}
                name={' '}
                page={pageD}
                setPage={setPageD}
                getDebitTableDetails={getDebitTableDetails}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

export default DebitTableView;
