import React from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import DebitTableView from './DebitTableView';
import CreditTableView from './CreditTableView';
import ProfileScreen from './ProfileScreen';

const TabTitle = ({ title, value, percentage, progress }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap">{title}</h6>
    <h5 className="text-800">{value}</h5>
    {progress && (
      <Flex alignItems="center">
        <FontAwesomeIcon
          icon={progress ? 'caret-up' : 'caret-down'}
          className={progress ? 'text-success' : 'text-warning'}
        />
        <h6
          className={`fs-11 mb-0 ms-2 ${
            progress ? 'text-success' : 'text-warning'
          }`}
        >
          {percentage}
        </h6>
      </Flex>
    )}
  </div>
);

function ActionTable() {
  return (
    <>
      <div className={classNames('overflow-hidden')}>
        <ProfileScreen />
        <Tab.Container id="audience-tab" defaultActiveKey="users">
          <SimpleBarReact>
            <Card.Header className="p-0 bg-body-tertiary">
              <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="users">
                    <TabTitle
                      title=""
                      value="Debit Table"
                      progress={false}
                      percentage=""
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="sessions">
                    <TabTitle
                      title=""
                      value="Credit Table"
                      progress={false}
                      percentage=""
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
          </SimpleBarReact>

          <Tab.Content className="mt-3">
            <Tab.Pane unmountOnExit eventKey="users">
              <DebitTableView />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="sessions">
              <CreditTableView />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  progress: PropTypes.bool
};

export default ActionTable;
