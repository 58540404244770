import Flex from 'components/common/Flex';
import React from 'react';
import PropTypes from 'prop-types';

function Timer({ remainingTime, label }) {
  const minutes = String(remainingTime?.minutes()).padStart(2, '0');
  const seconds = String(remainingTime?.seconds()).padStart(2, '0');

  // if (remainingTime?.minutes() === 0 && remainingTime?.seconds() <= 30) { }

  const formattedTime = `${minutes}${seconds}`;

  const [digit1, digit2, digit3, digit4] = formattedTime.split('');

  if (!remainingTime) {
    return <h5 className="fs-10">Loading...</h5>;
  }

  return (
    <>
      <h5 className="fs-10">{label}</h5>
      <Flex className="flex">
        <h5 className="fs-7">{digit1}</h5>

        <h5 className="fs-7">{digit2}</h5>

        <h5 className="fs-7">:</h5>

        <h5 className="fs-7">{digit3}</h5>

        <h5 className="fs-7">{digit4}</h5>
      </Flex>
    </>
  );
}

Timer.propTypes = {
  remainingTime: PropTypes.any,
  label: PropTypes.any
};

export default Timer;
