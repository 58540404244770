import { API_URL } from './config';

export async function getBettingTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll/admin/${page}/${pageSize}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteBettingTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};

export async function getBettingColorTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-color/admin/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteBettingColorTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-color/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};

export async function getOneMinuteBettingTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-one/admin/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteOneMinuteBettingTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-one/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};

export async function getOneMinuteBettingColorTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-color-one/admin/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteOneMinuteBettingColorTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-color-one/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};

export async function getFiveMinutesBettingTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-five/admin/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteFiveMinutesBettingTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-five/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};

export async function getFiveMinutesBettingColorTable(
  authToken,
  page,
  pageSize
) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-color-five/admin/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching user');
  }
  return await res.json();
}

export const deleteFiveMinutesBettingColorTable = async (authToken, count) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-color-five/multi/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      count: Number(count)
    })
  });

  console.log('Response status:', res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log('Non-JSON response:', textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error('Network error');
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log('JSON response:', jsonResult);
      return jsonResult;
    } catch (error) {
      console.error('Error parsing JSON response:', error);
      throw error;
    }
  }
};
