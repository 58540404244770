import React from 'react';
import { Card } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import BettingListTableHeader from './BettingListTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CustomTableFooter from './CustomTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PropTypes from 'prop-types';
import NumberFormat from '../NumberFormat';

const BettingListTable = ({ data, name, page, setPage }) => {
  const columns = [
    {
      accessorKey: 'bettingItemID',
      header: 'ID',
      meta: {
        headerProps: {
          style: { minWidth: '8rem' },
          className: 'text-primary'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { bettingItemID } = original;
        return <p className="fw-semibold text-primary">{bettingItemID}</p>;
      }
    },
    {
      accessorKey: 'redNumberTotalAmount',
      header: '₹(1,3,5,7,9)',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { redNumberTotalAmount } = original;
        return (
          <p className="fw-semibold">
            {NumberFormat.currencyFormat(redNumberTotalAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'greenNumberTotalAmount',
      header: '₹(0,2,4,6,8)',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { greenNumberTotalAmount } = original;
        return (
          <p className="fw-semibold">
            {NumberFormat.currencyFormat(greenNumberTotalAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'redTotalAmount',
      header: '₹T(1,3,5,7,9)',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-danger'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { redTotalAmount } = original;
        return (
          <p className="fw-semibold text-danger">
            {NumberFormat.currencyFormat(redTotalAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'greenTotalAmount',
      header: '₹T(0,2,4,6,8)',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-success'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { greenTotalAmount } = original;
        return (
          <p className="fw-semibold text-success">
            {NumberFormat.currencyFormat(greenTotalAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'balanceAmount',
      header: '₹Ba-Ao',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-primary'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { balanceAmount } = original;
        return (
          <p className="fw-semibold text-primary">
            {NumberFormat.currencyFormat(balanceAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'eventResultAmount',
      header: '₹Ev-Re-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-warning'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { eventResultAmount } = original;
        return (
          <p className="fw-semibold text-warning">
            {NumberFormat.currencyFormat(eventResultAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'numberResultAmount',
      header: '₹Nu-Re-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-warning'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { numberResultAmount } = original;
        return (
          <p className="fw-semibold text-warning">
            {NumberFormat.currencyFormat(numberResultAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'profitFormNumber',
      header: '₹Pr-Fr-Nu',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-success'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { profitFormNumber } = original;
        return (
          <p className="fw-semibold text-success">
            {NumberFormat.currencyFormat(profitFormNumber)}
          </p>
        );
      }
    },
    {
      accessorKey: 'finalResultAmount',
      header: '₹Fi-Re-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { finalResultAmount } = original;
        return (
          <p className="fw-semibold">
            {NumberFormat.currencyFormat(finalResultAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'profitFromFinalResult',
      header: '₹Pr-Fr-Fi-Re',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-success'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { profitFromFinalResult } = original;
        return (
          <p className="fw-semibold text-success">
            {NumberFormat.currencyFormat(profitFromFinalResult)}
          </p>
        );
      }
    },
    {
      accessorKey: 'distributeResultAmount',
      header: '₹Di-Re-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-danger'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { distributeResultAmount } = original;
        return (
          <p className="fw-semibold text-danger">
            {NumberFormat.currencyFormat(distributeResultAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'distributeTotalAmount',
      header: '₹Di-To-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-danger'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { distributeTotalAmount } = original;
        return (
          <p className="fw-semibold text-danger">
            {NumberFormat.currencyFormat(distributeTotalAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'resultAmount',
      header: '₹Re-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-warning'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { resultAmount } = original;
        return (
          <p className="fw-semibold text-warning">
            {NumberFormat.currencyFormat(resultAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'resultEvent',
      header: 'Re-Ev',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { resultEvent } = original;
        return <p className="fw-semibold">{resultEvent}</p>;
      }
    },
    {
      accessorKey: 'resultNum',
      header: 'Re-Nu',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { resultNum } = original;
        return <p className="fw-semibold">{resultNum}</p>;
      }
    },
    {
      accessorKey: 'bettingAmount',
      header: '₹Be-Am',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-primary'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { bettingAmount } = original;
        return (
          <p className="fw-semibold text-primary">
            {NumberFormat.currencyFormat(bettingAmount)}
          </p>
        );
      }
    },
    {
      accessorKey: 'totalCompanyProfit',
      header: '₹To-Com-Pr',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-success'
        },
        cellProps: {
          className: 'py-2 pe-4'
        }
      },
      cell: ({ row: { original } }) => {
        const { totalCompanyProfit } = original;
        return (
          <p className="fw-semibold text-success">
            {NumberFormat.currencyFormat(totalCompanyProfit)}
          </p>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    columns,
    data: data?.data,
    selection: true,
    sortable: true,
    pagination: true,
    perPage: 10,
    selectionColumnWidth: 52
  });

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <BettingListTableHeader name={name} />
          </Card.Header>

          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-body-tertiary align-middle"
              rowClassName="btn-reveal-trigger align-middle"
              tableProps={{
                size: 'sm',
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>

          <Card.Footer>
            <CustomTableFooter
              page={page}
              setPage={setPage}
              totalPages={data?.totalPages}
              totalCount={data?.totalCount}
              nextPage={data?.nextPage}
              previousPage={data?.previousPage}
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  );
};

BettingListTable.propTypes = {
  data: PropTypes.any,
  name: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any
};

export default BettingListTable;
