import React from 'react';
import { createRoot } from 'react-dom/client';
// import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
// import { router } from 'routes';
import 'helpers/initFA';
import AppRouter from 'routes';
import { AuthContextProvider } from 'admin-dashboard/context/AuthContext';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AppProvider>
        {/* <RouterProvider router={router} /> */}
        <AppRouter />
      </AppProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
