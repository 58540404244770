import { API_URL } from './config';

export async function getTimer(authToken) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/timer-three/1`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching timer');
  }
  return await res.json();
}

export async function getTimerOne(authToken) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/timer-one/1`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching timer');
  }
  return await res.json();
}

export async function getTimerFive(authToken) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/timer-five/1`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  });
  if (res.status === 401) {
    throw new Error('Not authorized. Please sign in');
  }
  if (res.status !== 200) {
    throw new Error('Error fetching timer');
  }
  return await res.json();
}
