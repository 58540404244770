import React, { useEffect, useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import ColorOneMinute from './ColorOneMinute';
import DiceOneMinute from './DiceOneMinute';
import { getTimerOne } from 'admin-dashboard/api/timer';
import { useAuth } from 'admin-dashboard/context/AuthContext';
import moment from 'moment';
import Timer from 'admin-dashboard/dashboard/Timer';

const TabTitle = ({ title, value, percentage, progress }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap">{title}</h6>
    <h5 className="text-800">{value}</h5>
    {progress && (
      <Flex alignItems="center">
        <FontAwesomeIcon
          icon={progress ? 'caret-up' : 'caret-down'}
          className={progress ? 'text-success' : 'text-warning'}
        />
        <h6
          className={`fs-11 mb-0 ms-2 ${
            progress ? 'text-success' : 'text-warning'
          }`}
        >
          {percentage}
        </h6>
      </Flex>
    )}
  </div>
);

function OneMinute() {
  const { authToken } = useAuth();
  const [serverTimer, setServerTimer] = useState(null);
  const [serverLoading, setServerLoading] = useState(null);
  const timer = moment.unix(serverTimer);
  const [remainingTime, setRemainingTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const getTimerDetail = () => {
    setLoading(true);
    getTimerOne(authToken)
      .then(res => {
        setServerTimer(res.timer);
        setLoading(false);
        setServerLoading(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getTimerDetail();
  }, []);

  useEffect(() => {
    if (serverLoading) {
      getTimerDetail();
    }
  }, [serverLoading]);

  useEffect(() => {
    if (timer) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const timeDifference = timer.diff(currentTime);

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTime(moment.duration(0));

          // Check if the timer has already reached zero
          if (!serverLoading) {
            setServerLoading(true);
            // console.log("Timer reached zero");
          }
        } else {
          setRemainingTime(moment.duration(timeDifference));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer, serverLoading]);

  return (
    <>
      {loading ? (
        <Card>
          <p className="p-3 pt-4">Loading...</p>
        </Card>
      ) : (
        <div className={classNames('overflow-hidden')}>
          <Tab.Container id="audience-tab" defaultActiveKey="users">
            <SimpleBarReact>
              <Card.Header className="p-0 bg-body-tertiary">
                <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                  <Nav.Item>
                    <Nav.Link className="mb-0" eventKey="">
                      <Timer remainingTime={remainingTime} label={'Counter'} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0" eventKey="users">
                      <TabTitle
                        title=""
                        value="Color"
                        progress={false}
                        percentage=""
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0" eventKey="sessions">
                      <TabTitle
                        title=""
                        value="Dice"
                        progress={false}
                        percentage=""
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
            </SimpleBarReact>

            <Tab.Content className="mt-3">
              <Tab.Pane unmountOnExit eventKey="users">
                <ColorOneMinute />
              </Tab.Pane>
              <Tab.Pane unmountOnExit eventKey="sessions">
                <DiceOneMinute />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </>
  );
}

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  progress: PropTypes.bool
};

export default OneMinute;
